import { Ref, computed, ref } from "vue";
import { usePrerender } from "../composables/prerender";

const { isPrerendering } = usePrerender();

const bootstrap = ref<{ Tooltip: any; Modal?: any; } | undefined>();

if (!isPrerendering && !bootstrap.value) { 
  import("bootstrap").then((value) => {
    bootstrap.value = value;
  })
}

export function getTooltip() {
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  return bootstrap.value?.Tooltip;
}

export function getModal() {
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  return bootstrap.value?.Modal;
}

export function getOrCreateTooltipInstance(ref: Ref) {
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  const Tooltip = getTooltip();
  if (!Tooltip) return;
  Tooltip.getOrCreateInstance(ref.value, { trigger: "hover focus" });
}

export function showModal(ref: Ref, onHidden?: () => void) {
  if (!ref.value) return;
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  const Modal = getModal();
  if (!Modal) return;
  const modal = Modal.getOrCreateInstance(ref.value);
  if (!modal) return;
  if (onHidden) ref.value.addEventListener('hidden.bs.modal', onHidden);
  modal.show();
}

export function hideModal(ref: Ref, onHidden?: () => void) {
  if (!ref.value) return;
  if(onHidden) ref.value?.removeEventListener('hidden.bs.modal', onHidden);
  getModal().getInstance(ref.value!)?.hide();
}

export function setTooltipContent(ref: Ref, content: any) {
  if (!ref.value) return;
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  const Tooltip = getTooltip();
  if (!Tooltip) return;
  const tooltip = Tooltip.getInstance(ref.value);
  if (!tooltip) return;
  tooltip.setContent(content);
}

export function hideTooltip(ref: Ref) {
  if (!ref.value) return;
  console.assert(!isPrerendering, new Error());
  console.assert(!!bootstrap.value, new Error());
  const Tooltip = getTooltip();
  if (!Tooltip) return;
  const tooltip = Tooltip.getInstance(ref.value);
  if (!tooltip) return;
  tooltip.hide();
}

export const bootstrapLoaded = computed(() => !!bootstrap.value);


export function useBootstrap() {
  return {
    bootstrapLoaded,
    getTooltip,
    getOrCreateTooltipInstance,
    setTooltipContent,
    hideTooltip,
    getModal,
    showModal,
    hideModal
  }
}