export { onHydrationEnd }
 
import type { OnHydrationEndAsync } from 'vike/types'
import { useCookies } from 'vue3-cookies';
import { usePostHydrationState } from '../composables/postHydrationState';
import { Ref } from 'vue';

const { cookies } = useCookies();
const { accepted, strictlyNecessary, functionality, targeting, tracking } = usePostHydrationState();

type CookieConsent = {
  "strictly-necessary"?: boolean,
  functionality?: boolean,
  targeting?: boolean,
  tracking?: boolean
};


const onHydrationEnd: OnHydrationEndAsync = async (
  pageContext
): ReturnType<OnHydrationEndAsync> => {

  const latestCookieConsentAccepted = cookies.get('cookie_consent_user_accepted') === "true";
  const latestCookieConsent = (cookies.get('cookie_consent_level') || {}) as unknown as CookieConsent;

  function coerceUncertainty(inMemory: Ref<boolean>, fromCookie?: boolean) {
    if (fromCookie !== undefined) inMemory.value = fromCookie;
  }

  // We must be certain. If we don't know, we now know no.
  coerceUncertainty(accepted, latestCookieConsentAccepted);
  coerceUncertainty(strictlyNecessary, latestCookieConsent["strictly-necessary"]); // for strictly necessary cookies, doesn't matter if they've accepted yet
  coerceUncertainty(functionality, latestCookieConsent?.functionality);  
  coerceUncertainty(targeting,  latestCookieConsent?.targeting);
  coerceUncertainty(tracking, latestCookieConsent?.tracking);


}

