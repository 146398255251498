import { computed, ref } from "vue";
import { Stop } from "../models/stop";
import { Service } from "./service";
import { AuthControl } from "../authControl";
import { GoogleService } from "./googleService";

export type GetPhotoResult = {
  photoUrl: string;
  photoAttributions: Array<string> | undefined;
}

export interface PhotosService extends Service {
  getPhoto(attrContainer: HTMLDivElement, stop: Stop): Promise<GetPhotoResult | undefined>
}

export function createPhotosService(
  authControl: AuthControl,
  googleService: GoogleService) {

  return new PhotosServiceImpl(authControl, googleService);
}

export class PhotosServiceImpl  {
  public loading = ref(0);

  public readonly isLoading = computed<boolean>(() => (this.loading.value > 0));
  public readonly lastErrorMessage = ref<string | undefined>();

  private photos?: Map<string, GetPhotoResult>;
  private googleService: GoogleService;

  constructor(authControl: AuthControl, googleService: GoogleService) {
    this.googleService = googleService;
    this.photos = new Map<string, GetPhotoResult>;

    authControl.onAuthStateChanged((isSignedIn) => {
      if (isSignedIn) {
        this.photos = new Map<string, GetPhotoResult>;
      } else {
        delete this.photos;
        this.lastErrorMessage.value = undefined;
      }
    });

  }

  public async getPhoto(attrContainer: HTMLDivElement, stop: Stop): Promise<GetPhotoResult | undefined> {
    const lastErrorMessage = this.lastErrorMessage;
    const photos = this.photos;
    const googleService = this.googleService;

    if (!googleService.googleRef.value) {
      console.log('No google ref.');
      return;
    }

    if (!photos) {
      console.log('No photos');
      return;
    }

    if (stop.photoUrl) {
      // We already have a photo right there in the stop, return it.
      const photoResult = {
        photoUrl: stop.photoUrl,
        photoAttributions: stop.photoAttributions
      }
      photos.set(stop.stopId, photoResult);
    }

    const photoResult = photos.get(stop.stopId);
    if (photoResult) {
      // We already have a photo for this stop - probably just re-arranged the stops, but
      // since Google doesn't allow us to store the photos on the server, we can't tell.
      return photoResult;
    }

    try {
      this.loading.value++;

      function placePhotoCallback(
        place: google.maps.places.PlaceResult | null, 
        status: google.maps.places.PlacesServiceStatus, 
        resolve: (result?: GetPhotoResult) => void, 
        reject: (error: Error) => void ) {

        if (status != "OK") {
          const message = `Unexpected status from Google PlacesService: ${status}`;
          console.error(message)
          reject(new Error(message));
          return;
        }

        const placePhotos = place?.photos;
        if (photos && placePhotos && placePhotos.length) {
          const photoResult = {
            photoUrl: placePhotos[0].getUrl(),
            photoAttributions: placePhotos[0].html_attributions
          };
          photos.set(stop.stopId, photoResult);
          return resolve(photoResult)
        } else {
          return resolve(undefined);
        }
      }

      const placesService = googleService.getPlacesService(attrContainer);

      async function getPhotoForGooglePlaceId(googlePlaceId: string): Promise<GetPhotoResult | undefined> {

        return new Promise((resolve, reject) => {

          if (!googlePlaceId) {
            // We don't have a photo and don't know how to get one.
            console.log('No custom photo and no Google placeId (this is sad)');
            return resolve(undefined);
          }
          
          placesService.getDetails({
            placeId: googlePlaceId,
            fields: ["photo"],
          }, (place, status) => {
            placePhotoCallback(place, status, resolve, reject);            
          });
        });
      }

      // If we have a place Id, try that one first.
      if (stop.googlePlaceId) {
        const specificPlacePhotoResult = await getPhotoForGooglePlaceId(stop.googlePlaceId);
        if (specificPlacePhotoResult) { return specificPlacePhotoResult; }
      }

      // If we get here, we didn't have a place id, or couldn't get a photo for the place id we have.
      // Check to see if we might have a distinct, more general description name, and if so, try to get it's photo
      if (stop.descriptionName && (stop.descriptionName != stop.navigationName)) {
        const query = stop.descriptionName;

        return await new Promise((resolve, reject) => {
          placesService.findPlaceFromQuery({
            fields: ["photo"],
            query
          }, (places, status) => {
            const place = places ? places[0] : null;
            placePhotoCallback(place, status, resolve, reject);
          });
        });
      }

    } catch (error /* The loader doesn't actually throw an 'Error', per se... */) {
      lastErrorMessage.value = "Something went wrong calling Google Maps services. Please try again later.";
      return;
    } finally {
      this.loading.value--;
    }

  }
}
