import { ref } from "vue";

const accepted = ref<boolean>(false);
const functionality = ref<boolean>(false);
const strictlyNecessary = ref<boolean>(false);
const targeting = ref<boolean>(false);
const tracking = ref<boolean>(false);

export function usePostHydrationState() {
  return { functionality, strictlyNecessary, targeting, tracking, accepted };
}