<template>
  <form class="container border border-rounded rounded-3 bg-white p-3 mb-3">
    <div class="mb-3">
      <label :for="props.emailAddressInputId" class="form-label">Email address</label>
      <input 
        type="email" 
        :id="props.emailAddressInputId" 
        autocomplete="username" 
        class="form-control" 
        :aria-describedby="emailAddressHelpText?.id" 
        v-model="emailAddress">
      <div v-uid ref="emailAddressHelpText" class="form-text mb-3">
        Please provide a valid email address.
      </div>
    </div>
    <div class="mb-3">
      <label :for="props.passwordInputId" class="form-label">Password</label>
      <input 
        type="password" 
        :id="props.passwordInputId" 
        autocomplete="new-password" 
        class="form-control" 
        :aria-describedby="passwordHelpText?.id" 
        v-model="password">
      <div v-uid ref="passwordHelpText" class="form-text mb-3">
        Please provide a strong unique password to create an account.
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-end">
            <ERTPBooleanIcon :icon-value="acceptableEmailAddress"/>
          </div>
          <div class="col-10 text-start">Email address looks valid</div>        
        </div>
        <div class="row">
          <div class="col text-end">
            <ERTPBooleanIcon :icon-value="passwordMinimumLength"/>
          </div>
          <div class="col-10 text-start">Password is at least {{MINIMUM_PASSWORD_LENGTH}} characters</div>        
        </div>
      </div>
    </div>
  </form>

  <button class="d-block mx-auto btn btn-primary mb-3" :disabled="signingUp || !canSignUp"
    @click.prevent="signUp">
    <span v-show="!signingUp">Create Account</span>
    <span v-show="signingUp">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>   Signing in...
    </span>
  </button>

  <div class="text-center mb-3">
    <small>or continue with Google</small>
  </div>

  <div class="text-center mb-3">
    <GoogleLogin :callback="googleLoginCallback"/>
  </div>

  <div class="border border-rounded rounded-3 px-3 py-2 mb-3">
    <small class="text-muted text-center mb-3"><p>We'd also like to invite you to preview experimental and under construction features.</p></small>
    <div class="d-flex justify-content-center">
      <div class="form-check text-muted">
        <input class="form-check-input" type="checkbox" value="" v-uid ref="previewOptInRef" v-model="previewOptIn">
        <label class="form-check-label text-muted" :for="previewOptInRef?.id">
          <small>Opt-in to preview features</small>
        </label>
      </div>
    </div>
  </div>

  <div v-show="errorMessage" class="alert alert-warning text-start mt-3" role="alert">
    <p>{{ errorMessage }}</p>
    <div v-show="displayPasswordResetLink && possiblyValidEmailAddress(emailAddress)">
      <p class="text-center">Send a <a href="#" @click="sendPasswordResetEmail">password reset</a> email.</p>
    </div>
  </div>


  <div class="text-center mb-3">
    <small>By continuing, you are indicating that you accept our <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy"  target="_blank">Privacy Policy.</a></small>
  </div>


</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { GoogleLogin, CallbackTypes } from "vue3-google-login"
import { AuthControl } from '../../authControl';
import { injectRequired } from '../../utilities/injectRequired';
import ERTPBooleanIcon from '../ERTPBooleanIcon.vue';
import { useProfile } from '../../composables/profile';
import { useServices } from '../../composables/services';

const props=defineProps<{
  emailAddressInputId: string,
  passwordInputId: string,
}>();

const emit = defineEmits(['onSuccess', 'onError']);

const emailAddressHelpText = ref();
const passwordHelpText = ref();

const authControl = injectRequired<AuthControl>('authControl');

const {
  profilesService
} = useServices();

const {
  profile,
} = useProfile();

const emailAddress = ref<string>('');
const password = ref<string>('');
const signingUp = ref<boolean>(false);
const errorMessage = ref<string>();
const displayPasswordResetLink = ref<boolean>(false);

const acceptableEmailAddress = computed(() => {
  return possiblyValidEmailAddress(emailAddress.value);
});

const passwordMinimumLength = computed(() => {
  return passwordMeetsMinimumLength(password.value);
});

const canSignUp = computed(
  () => {
    if (!possiblyValidEmailAddress(emailAddress.value)) return false;
    if (password.value === '') return false;
    if (!passwordMeetsMinimumLength(password.value)) return false;
    return true;
  }
)

/**
 * @see https://pages.nist.gov/800-63-3/sp800-63b.html, 5.1.1 Memorized Secrets 13FEB2024
 */
const MINIMUM_PASSWORD_LENGTH = 8;

/**
 * Checks for and rejects passwords that do not meet minimum length requirements
 * 
 * @param password The password to check
 * 
 * @returns true if password has enough characters, false if it does not
 */
function passwordMeetsMinimumLength(password: string) {
  return password.length >= MINIMUM_PASSWORD_LENGTH;
}

/**
 * Checks for and rejects some of the most common errors users make when entering email addresses.
 * This is a simple heuristic, and does NOT formally validate an email address according to the RFCs.
 * 
 * @param emailAddress The email address to check
 * 
 * @returns true if emailAddress is possibly valid, false if emailAddress is invalid specifically due to a missing '@' symbol or '.' symbol.
 */
function possiblyValidEmailAddress(emailAddress: string) {
  return String(emailAddress)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/) != null;
};

async function signUp() {
  signingUp.value = true;
  try {
    await authControl.createUserWithEmailAndPassword(emailAddress.value, password.value, window.location.href);
    await profilesService.createProfile({previewOptIn: previewOptIn.value});
    await profilesService.populateProfile();
    emit("onSuccess");

  } catch(error) {
    const code = (error as any).code;
    errorMessage.value = (error as Error).message;    
    switch(code) {
      case "auth/invalid-email":
        errorMessage.value = "Invalid email address.\nPlease check the email address you provided and try again."
        break;
      case "auth/wrong-password":
        errorMessage.value = "Unknown or invalid password.\nPlease check your email address and password and try again."
        displayPasswordResetLink.value = true;
        break;
      case "auth/too-many-requests":
        errorMessage.value = "Access to this account has been temporarily disabled due to many failed login attempts.\nYou can immediately restore it by resetting your password or you can try again later."
        displayPasswordResetLink.value = true;
        break;
      case "auth/weak-password":
        errorMessage.value = `Password should be at least ${MINIMUM_PASSWORD_LENGTH} characters to sign up.\nIf you already have an account, check your email address and password and try again. If you are creating a new account, please use a stronger password.`
        break;
      case "auth/email-already-in-use":
        errorMessage.value = `That email address is already in use.\nIf you're unable to login with the current password, we can send a password reset email to that email address.`
        displayPasswordResetLink.value = true;
        break;
      default:
        console.error(error);
        errorMessage.value = "Something went wrong. Please try again."
        break;
    }
    emit("onError", error);
  } finally {
    signingUp.value = false;
  }
}

async function sendPasswordResetEmail() {
  await authControl.sendPasswordResetEmail(emailAddress.value, window.location.href);
  errorMessage.value = "We've sent you a password reset email.\nPlease follow the instructions in the email and try again."
  displayPasswordResetLink.value = false;
}

const previewOptIn = ref(false);
const previewOptInRef = ref();

async function googleLoginCallback(response: CallbackTypes.CredentialPopupResponse) {
  try {
    await authControl.signInWithCredential(response.credential);
    await profilesService.populateProfile();
    if (!profile.value) {
      console.log('No profile, creating one...')
      await profilesService.createProfile({previewOptIn: previewOptIn.value})
      await profilesService.populateProfile();
    }
    emit("onSuccess")
  } catch (error) {
    errorMessage.value = (error as Error).message;    
    emit("onError", error)
  }
}

</script>
