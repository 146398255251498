<template>
  <nav v-if="!viewingHome" class="mx-3" aria-label="breadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><a href="/">Home</a></li>
      <li v-if="showRoadtripLink" class="breadcrumb-item"><a :href="roadtrip?.link">Road Trip</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ currentPage }}</li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { usePageContext } from 'vike-vue/usePageContext';
import { useRoadtrip } from '../composables/roadtrip';
import { computed } from 'vue';

const pageContext = usePageContext();

const { roadtrip } = useRoadtrip();

const viewingHome = computed(() => {
  return pageContext.urlPathname == "/"
})

const viewingRoadtrip = computed(() => {
  return pageContext.urlPathname.startsWith("/roadtrips");
})

const viewingStop = computed(() => {
  return pageContext.urlPathname.startsWith("/stops");
})

const viewingCampground = computed(() => {
  return pageContext.urlPathname.startsWith("/campgrounds");
})

const viewingProfile = computed(() => {
  return pageContext.urlPathname.startsWith("/profile");
})

const viewingPrivacy = computed(() => {
  return pageContext.urlPathname.startsWith("/privacy");
})

const viewingTerms = computed(() => {
  return pageContext.urlPathname.startsWith("/terms");
})

const viewingFAQ = computed(() => {
  return pageContext.urlPathname.startsWith("/faq");
})

const viewingPricing = computed(() => {
  return pageContext.urlPathname.startsWith("/pricing");
})


const currentPage = computed(() => {
  if(viewingHome.value) return "Home";
  if(viewingRoadtrip.value) return "Road Trip";
  if(viewingStop.value) return "Stop";
  if(viewingCampground.value) return "Campground";
  if(viewingProfile.value) return "Profile";
  if(viewingPrivacy.value) return "Privacy";
  if(viewingTerms.value) return "Terms";
  if(viewingFAQ.value) return "FAQ";
  if(viewingPricing.value) return "Pricing";
})

const showRoadtripLink = computed(() => {
return ((viewingStop.value || viewingCampground.value) && roadtrip.value?.link)

})
</script>
