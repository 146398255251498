<template>
  <ul class="navbar-nav">
    <li class="nav-item dropdown">
      <DropdownMenuButton class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"/>
      <ERTPHeaderNavDropdownMenu :urlPathname="props.urlPathname"/>
    </li>
  </ul>
</template>

<script setup lang="ts">

import { clientOnly } from 'vike-vue/clientOnly'
const DropdownMenuButton = clientOnly(() => import('../components/buttons/DropdownMenuButton.vue'));
const ERTPHeaderNavDropdownMenu = clientOnly(() => import('../components/ERTPHeaderNavDropdownMenu.vue'));

const props = defineProps<{
  urlPathname: string
}>();

</script>
