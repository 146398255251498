<template>
  <div v-if="show" ref="signUpModalRef" class="modal" tabindex="-1" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create a New Account</h5>
          <button type="button" class="btn-close" @click="onCancel" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-show="isLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-show="!isLoading">
            <ERTPSignUpForm 
              emailAddressInputId="ERTPSignUpModalEmailAddressInputId"
              passwordInputId="ERTPSignUpModalPasswordInputId"
              @on-success="onSuccess"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import ERTPSignUpForm from '../forms/ERTPSignUpForm.vue';

import { useUserState } from '../../composables/userState';
import { useServices } from '../../composables/services';
import { useBootstrap } from '../../composables/bootstrap';

const { 
  showModal,
  hideModal,  
} = useBootstrap();

const { userState } = useUserState();
const { profilesService } = useServices();

const emit = defineEmits<{
  onSuccess: [],
  onCancel: [],
  'update:show': [value: boolean]
}>();

const isLoading = computed(() => {
 return userState.value.loading > 0
    || profilesService.isLoading.value
});

const props = defineProps<{show: boolean | undefined}>();

const signUpModalRef = ref<HTMLDivElement>();

watch(
  [() => props.show, signUpModalRef], 
  () => {
    if (!signUpModalRef.value) return; // probably not mounted
    if (props.show) { 
      showModal(signUpModalRef, () => {
        if (props.show) { onCancel(); }
      })
    } else {
      hideModal(signUpModalRef);
    }
  }, 
  {immediate: true}
);

function onCancel() {
  emit('onCancel')
  emit('update:show', false);
}

function onSuccess() {
  emit('onSuccess')
  emit('update:show', false);
}
</script>
