<template>
  <div v-show="userState.loading" class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-show="!userState.loading && (!userState.isSignedIn || userState.isAnonymous)">

    <form class="container border border-rounded rounded-3 bg-white p-3 mb-3">
      <div class="mb-3">
        <label :for="props.emailAddressInputId" class="form-label">Email address</label>
        <input 
          type="email" 
          :id="props.emailAddressInputId" 
          autocomplete="username" 
          class="form-control" 
          :aria-describedby="emailAddressHelpText?.id" 
          v-model="emailAddress">
        <div v-uid ref="emailAddressHelpText" class="form-text mb-3">
          Please provide a valid email address.
        </div>
      </div>
      <div class="mb-3">
        <label :for="props.passwordInputId" class="form-label">Password</label>
        <input 
          type="password" 
          :id="props.passwordInputId" 
          autocomplete="current-password" 
          class="form-control" 
          :aria-describedby="passwordHelpText?.id" 
          v-model="password">
        <div v-uid ref="passwordHelpText" class="form-text mb-3">
          Please provide your password to sign in.
        </div>
      </div>
      <div class="text-center">
        <button class="d-block mx-auto btn btn-primary" :disabled="signingIn || !canSignIn"
          @click.prevent="signIn">
          <span v-show="!signingIn">Continue</span>
          <span v-show="signingIn">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>   Signing in...
          </span>
        </button>          
        
        <div v-show="errorMessage" class="alert alert-warning text-start mt-3" role="alert">
          <p>{{  errorMessage }}</p>
          <div v-show="displayPasswordResetLink && possiblyValidEmailAddress(emailAddress)">
            <p class="text-center">Send a <a href="#" @click="sendPasswordResetEmail">password reset</a> email.</p>
          </div>
        </div>
      </div>
    </form>

    <div class="text-center mb-3">
      <small>or continue with Google</small>
    </div>

    <div class="text-center mb-3">
      <GoogleLogin :callback="googleLoginCallback"/>
    </div>

    <div class="text-center mb-3">
      <small>By continuing, you are indicating that you accept our <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy"  target="_blank">Privacy Policy.</a></small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { GoogleLogin, CallbackTypes } from "vue3-google-login"
import { AuthControl } from '../../authControl';
import { injectRequired } from '../../utilities/injectRequired';
import { possiblyValidEmailAddress } from '../../utilities/inputValidation';
import { useProfile } from '../../composables/profile';
import { useUserState } from '../../composables/userState';
import { useServices } from '../../composables/services';

const props=defineProps<{
  emailAddressInputId: string,
  passwordInputId: string,
}>();

const emit = defineEmits(['onSuccess', 'onError']);

const emailAddressHelpText = ref();
const passwordHelpText = ref();

const { userState } = useUserState();
const authControl = injectRequired<AuthControl>('authControl')!;

const { profilesService } = useServices();

const {
  profile,
} = useProfile();


const emailAddress = ref<string>('');
const password = ref<string>('');
const signingIn = ref<boolean>(false);
const errorMessage = ref<string>();
const displayPasswordResetLink = ref<boolean>(false);

const canSignIn = computed(
  () => {
    if (!possiblyValidEmailAddress(emailAddress.value)) return false;
    if (password.value === '') return false;
    return true;
  }
)

async function signIn() {
  signingIn.value = true;
  try {
    await authControl.signInWithEmailAndPassword(emailAddress.value, password.value, window.location.href);
    emit("onSuccess");
  } catch(error) {
    const code = (error as any).code;
    errorMessage.value = (error as Error).message;    
    switch(code) {
      case "auth/invalid-email":
        errorMessage.value = "Invalid email address.\nPlease check the email address you provided and try again."
        break;
      case "auth/wrong-password":
      case "auth/invalid-credential":
        errorMessage.value = "Unknown or invalid password.\nPlease check your email address and password and try again."
        displayPasswordResetLink.value = true;
        break;
      case "auth/too-many-requests":
        errorMessage.value = "Access to this account has been temporarily disabled due to many failed login attempts.\nYou can immediately restore it by resetting your password or you can try again later."
        displayPasswordResetLink.value = true;
        break;
      case "auth/weak-password":
        errorMessage.value = "Password should be at least 6 characters to sign up.\nIf you already have an account, check your email address and password and try again. If you are creating a new account, please use a stronger password."
        break;
      default:
        console.error(error);
        errorMessage.value = "Something went wrong. Please try again."
        break;
    }
    emit("onError", error);
  } finally {
    signingIn.value = false;
  }
}

async function sendPasswordResetEmail() {
  await authControl.sendPasswordResetEmail(emailAddress.value, window.location.href);
  errorMessage.value = "We've sent you a password reset email.\nPlease follow the instructions in the email and try again."
  displayPasswordResetLink.value = false;
}

async function googleLoginCallback(response: CallbackTypes.CredentialPopupResponse) {
  try {
    await authControl.signInWithCredential(response.credential);
    await profilesService.populateProfile();
    if (!profile.value) {
      console.log('No profile, creating one...')
      await profilesService.createProfile();
      await profilesService.populateProfile();
    }
    emit("onSuccess")
  } catch (error) {
    errorMessage.value = (error as Error).message;    
    emit("onError", error)
  }
}

</script>
