import { useServices } from './services';

export function useProfile() {

  const { profilesService } = useServices();

  return { 
    profile: profilesService?.profile,
  }
};
